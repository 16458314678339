/* purgecss start ignore */
@tailwind base;

@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");

input, select{
    outline: none;
}